<!-- 福彩3D基本走势图 -->
<template>
    <div class="">
        <div class="ssq-table">
            <div class="ssq-table-header">
                <div class="ssq-icon">
                    <svg t="1621310782405" class="icon" viewBox="0 0 1024 1024" version="1.1"
                        xmlns="http://www.w3.org/2000/svg" p-id="2538" width="45" height="45">
                        <path
                            d="M541.408711 157.6704h122.3936l44.347733-84.1216-199.529244-1.803378-266.146133 660.383289h63.644444z"
                            fill="#ffffff" p-id="2539"></path>
                        <path
                            d="M585.764978 271.402667h127.689955l44.828445-90.641067h-203.374934l-264.220444 666.843022h61.715911z"
                            fill="#ffffff" p-id="2540"></path>
                        <path
                            d="M813.343289 294.408533h-210.759111l-270.427022 657.846045h209.251555l90.643911-205.960534h126.2336s207.414044 9.910044 234.416356-232.490666c0 0 11.5712-160.924444-179.359289-219.394845z m1.823289 260.667734c-49.169067 80.275911-133.046044 52.315022-133.046045 52.315022l65.558756-159.647289c116.656356 6.764089 67.487289 107.335111 67.487289 107.332267zM203.901156 506.587022c40.4992-92.020622 128.253156-54.129778 128.253155-54.129778l69.415822-158.048711c0-14.819556-323.487289-52.562489-369.316977 239.246223-17.3568 176.822044 177.874489 204.694756 177.874488 204.694755l51.646578-119.893333c-96.079644-59.630933-57.873067-111.869156-57.873066-111.869156z"
                            fill="#ffffff" p-id="2541"></path>
                    </svg>
                    <div class="ssq-one">
                        <h2>中国福利彩票</h2>
                    </div>
                    <div class="ssq-two">
                        <h3>福彩 3 D 基 本 走 势 图</h3>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <table class="tab" border="1" cellspacing="0" cellpadding="0">
                <thead class="thead-fixed">
                    <tr>
                        <th rowspan="2" class="ranks_a b">期号</th>
                        <th rowspan="2" class="ranks_a1">开奖</th>
                        <th colspan="10" class="ranks_b">百位</th>
                        <th colspan="10" class="ranks_b">十位</th>
                        <th colspan="10" class="ranks_b">个位</th>
                        <th colspan="28" class="ranks_b">和值</th>
                    </tr>

                    <tr>
                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>

                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>

                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>

                        <th class="ranks_c">0</th>
                        <th class="ranks_c">1</th>
                        <th class="ranks_c">2</th>
                        <th class="ranks_c">3</th>
                        <th class="ranks_c">4</th>
                        <th class="ranks_c">5</th>
                        <th class="ranks_c">6</th>
                        <th class="ranks_c">7</th>
                        <th class="ranks_c">8</th>
                        <th class="ranks_c">9</th>
                        <th class="ranks_c">10</th>
                        <th class="ranks_c">11</th>
                        <th class="ranks_c">12</th>
                        <th class="ranks_c">13</th>
                        <th class="ranks_c">14</th>
                        <th class="ranks_c">15</th>
                        <th class="ranks_c">16</th>
                        <th class="ranks_c">17</th>
                        <th class="ranks_c">18</th>
                        <th class="ranks_c">19</th>
                        <th class="ranks_c">20</th>
                        <th class="ranks_c">21</th>
                        <th class="ranks_c">22</th>
                        <th class="ranks_c">23</th>
                        <th class="ranks_c">24</th>
                        <th class="ranks_c">25</th>
                        <th class="ranks_c">26</th>
                        <th class="ranks_c">27</th>




                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item2 ,x) in fc3d" :key='x'>
                        <td>{{item2.expect}}</td>
                        <td>
                            <div>
                                {{item2.red[0] }} {{item2.red[1] }} {{item2.red[2] }}
                            </div>
                        </td>
                        <td class="b-red" v-for="(item ,i) in q1" :key='i'>
                            <div :class="item == item2.red[0] ? 'q-red': '' ">
                                {{item == item2.red[0] ? item: '' }}
                            </div>
                        </td>
                        <td class="b-lan" v-for="(item ,i) in q1" :key='i'>
                            <div :class="item == item2.red[1] ? 'q-lan': '' ">
                                {{item == item2.red[1] ? item: '' }}
                            </div>
                        </td>
                        <td class="b-red" v-for="(item ,i) in q1" :key='i'>
                            <div :class="item == item2.red[2] ? 'q-red': '' ">
                                {{item == item2.red[2] ? item: '' }}
                            </div>
                        </td>
                        <td class="b-lan" v-for="(item ,i) in q2" :key='i'>
                            <div
                                :class="item == (parseInt(item2.red[0])+parseInt(item2.red[1])+parseInt(item2.red[2])) ? 'q-he': '' ">
                                {{item == (parseInt(item2.red[0])+parseInt(item2.red[1])+parseInt(item2.red[2])) ? item:
                                '' }}
                            </div>
                        </td>
                    </tr>

                    <tr>
                        <td v-for="(x , i) in 60" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td v-for="(x , i) in 60" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td v-for="(x , i) in 60" :key='i'>
                            <div :class="x==i+1 ? 'xuan' : '' ">
                                {{"?"}}
                            </div>
                        </td>
                    </tr>

                </tbody>
            </table>
        </div>

    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex'
    import axios from 'axios'
    import config from '@/utils/config.js'
    //import '../js/font.js' //js文件引入，注意路径
    //import {myfun} from '../js/test.js' //js文件引入，注意路径
    //import Introduction from '@/views/Introduction.vue'
    export default {
        name: 'red-basic',
        components: {

        },
        data() { // 数据源
            return {
                q1: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9],
                q2: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27],
                he: [],
            }
        },
        mounted() {
            this.mssq50()
            this.toTop()
        },
        computed: {
            ...mapState(['fc3d'])
        },
        methods: { // 函数体部分，js的主要逻辑控制 
            ...mapMutations(['add', 'mutationfc3d']),
            ...mapActions(['actionfc3d']),
            toTop() {
                document.documentElement.scrollTop = 0;
            },
            mssq50() {//走势图
                if (this.fc3d == "") {
                    axios({
                        method: "post",
                        url: config.urlcom + "lottery/demo/findj",
                        data: {
                            'tab': "fc3d",
                            'zd': "issueno",
                            'i': 50,
                        },
                        // params: { record: 50 }
                    }).then(  //this.url是我在上面定义的一个固定的url
                        (res) => {
                            var data = []
                            var x = res.data.reverse()
                            x.forEach((item) => {
                                var red = item.number.split(' ')
                                var obj = {
                                    "expect": item.issueno,
                                    "red": red,
                                }
                                data.push(obj)
                            })
                            this.actionfc3d(data)
                        },
                    )
                    //alert('重新请求数据basic页')
                } else {
                    // alert('vux返回数据basic页')
                    return this.$store.state.fc3d;
                }
            },

        },
        props: {
        },
        watch: {
            // fc3d() {
            //     this.mred()
            // },

        }
    }
</script>

<style scoped>
    /* @import './swiper.css'; */
    .ssq-table-header {
        width: 100%;
        height: 50px;
        background-color: red;
    }

    .tab {
        margin: 0 auto;
        border-collapse: collapse;
    }

    .thead-fixed th {
        border: 1px solid #808080;
    }

    .ranks_a1 {
        width: 40px;
    }

    .ranks_c {
        width: 23px;
        color: red;
    }

    .ranks_d {
        width: 22px;
        color: blue;
    }

    .ssq-icon {
        text-align: left;
    }

    .icon {
        float: left;
    }

    .ssq-one {
        float: left;
        height: 42px;
        line-height: 42px;
        color: #fff;

    }

    .ssq-two {
        float: left;
        width: 80%;
        text-align: center;
        line-height: 42px;
        font-size: 25px;
        color: rgb(252, 251, 249);
    }

    .b-red {
        background-color: rgb(224, 191, 191);
    }

    .b-lan {
        background-color: #f8fcb2;
    }


    .q-red {
        background-color: rgb(87, 28, 114);
        border-radius: 15px;
        color: #fff;
    }

    .q-lan {
        background-color: #548ce0;
        border-radius: 15px;
        color: #fff;
    }

    .q-he {
        background-color: #22940b;
        border-radius: 15px;
        color: #fff;
    }

    tbody .xuan {
        background-color: rgb(160, 156, 156);
        border-radius: 15px;
        color: rgb(253, 253, 253);
    }
</style>